<script>
import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";
import PageHeader from "@/components/page-header";

import animationData from "@/components/widgets/nkmsrxys.json";
import animationData1 from "@/components/widgets/gsqxdxog.json";

import Lottie from "@/components/widgets/lottie.vue";
import serverConfig   from '@/helpers/config';
import Swal from "sweetalert2";
import axios from 'axios';
import { ref } from "vue";

import { mapActions, mapGetters} from "vuex";
//import moment from 'moment';


export default {
  page: {
    title: "Sommario ordine",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  setup() {
    const active = ref(false);
    const toggleActive = () => {
      active.value = !active.value;
    };

    let tk = 'Bearer '+localStorage.getItem('tk')

    return { active, toggleActive,tk };
  },
  components: {
    Layout,
    PageHeader,
    lottie: Lottie,
  },
  data() {
    return {
      UrlServer: serverConfig.EP,
      datastore: this.$store.state,
      userData: this.$store.state.auth.currentUser,
      title: "Sommario ordine",
      items: [
        {
          text: "Ecommerce",
          href: "/",
        },
        {
          text: "Sommario ordine",
          active: true,
        },
      ],
      taxRate: 0.125,
      shippingRate: 65.0,
      discountRate: 0.15,
      quantity: "",
      currencySign: "$",
      defaultOptions: { animationData: animationData },
      defaultOptions1: { animationData: animationData1 },
      value: 2,
      value1: 1,
      value2: 1,
      orderList:[],

    };
  },
  computed:{ 
    ...mapGetters('ecommercesettings', ['getCart', ]),   
  },
  mounted() {
    this.checkCart()
    this.orderList = this.getCart
    //this.getCart()
    // Remove product from cart
    const removeProduct = document.getElementById("removeItemModal");
    removeProduct.addEventListener("show.bs.modal", (e) => {
      document
        .getElementById("remove-product")
        .addEventListener("click", () => {
          e.relatedTarget.closest(".product").remove();
          document.getElementById("close-modal").click();
          this.recalculateCart();
        });
    });
    /*
    setTimeout(() => {
      this.isData();
    }, 100);
    */
  },
  methods: {
    ...mapActions('auth', ['getSalesRoute', ]),
    ...mapActions('ecommercesettings', ['addToCart','updateCart', 'resetCurrentCart' ,'getCartByOrd','deleteItem']),
    checkVar(value){
      if (value) {
        return typeof value
      }
    },

    formatPrice(value) {
      let val = (value/1).toFixed(2).replace('.',',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    checkCart(){
      if (Object.keys(this.datastore.ecommercesettings).length>0){    
        if (this.datastore.ecommercesettings.orx){
          localStorage.setItem('orx',this.datastore.ecommercesettings.orx)
        }
      }
    },

    editQty(id, op){
     
      let item = this.orderList.rows.filter(function(el){
        return el._id == id
      })
      
      if (item){
        if (item.length>0){
          
          if (op == '+'){
            item[0].detail_item.qta++
          } else {
            item[0].detail_item.qta--
             if (item[0].detail_item.qta<=0){
             
              item[0].detail_item.qta = 1
             }
          }

          this.addTC(item[0].gcode, item[0].CodMarca, item[0].detail_item.qta)

        }
      }
      
    },    
    addTC(gcode,codMarca, qty){
      if (Object.keys(this.datastore.ecommercesettings).length>0){    
        if (this.datastore.ecommercesettings.orx){
            let data = { 
                productCode: gcode,
                userId: this.userData.uid,
                cuuid:this.getCart.header.momentCode,
                orx:this.getCart.header.orx,
                salesRoute:this.getCart.header.canalePerUtente,
                qta: qty,
            }
            this.updateCart(data)
        }
      }
    },
    removeItem(id){
        if ( localStorage.getItem("orx") ) {
          Swal.fire(
            {
              title: this.$t("t-delete-confirm-setting-title"),
              text: this.$t("t-delete-confirm-setting-text"),
              icon: this.$t("t-delete-confirm-setting-icon"),
              showCancelButton: true,
              cancelButtonText: this.$t("t-delete-confirm-cancel-button-text"),
              cancelButtonColor: this.$t("t-delete-confirm-setting-cancelButtonColor"),
              confirmButtonColor: this.$t("t-delete-confirm-setting-confirmButtonColor"),
              confirmButtonText: this.$t("t-delete-confirm-setting-confirmButtonText")
            }
          ).then(() => {

            this.deleteItem(id, localStorage.getItem("orx"))
          })

        }
      },
    completeOrx(orx,status){
      if ( localStorage.getItem("orx") ) {
        axios.put(`${this.UrlServer}carts/status-update/${orx}`, 
          {
            status:status,
          },
          { 
            headers: { 'authorization':this.tk}
          }
        ).then(response=>{
          
          if (response.data){
            if (response.data.modifiedCount > 0) {
              this.resetCurrentCart()
              localStorage.removeItem('orx')
              this.$router.push(`/ecommerce/checkout/${orx}`)
            } else {
                Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
            }
          }
         
          }).catch((error) => {
            console.log(error)
              if (error == "Error: Request failed with status code 401")
              {
                // this.alertSessionExp()
              } else {
                Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
              }

        });
       
      }
    },
  
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row mb-3">
      <div class="col-xl-8">
        <div class="row align-items-center gy-3 mb-3">
          <div class="col-sm">
            <div>
              <h5 class="fs-14 mb-0">Il tuo ordine (<strong>{{ getCart.totalQta }} <template v-if="getCart.totalQta==1">prodotto</template><template v-if="getCart.totalQta>1">prodotti</template></strong> )</h5>
            </div>
          </div>
          <div class="col-sm-auto">
            <router-link
              to="/ecommerce/products"
              class="link-primary text-decoration-underline"
              >Continua ordine</router-link
            >
          </div>
        </div>
        
    <div v-for="item in getCart.rows" :key="item._id">
        <div class="card product">

          <div class="card-body">
            <div class="row gy-3">
              <div class="col-sm-auto">
                <div class="avatar-lg bg-light rounded p-1">
                  <img
                    src="@/assets/images/products/daikin-esempio.jpg"
                    alt=""
                    class="img-fluid d-block"
                  />
                </div>
              </div>
              <div class="col-sm">
                <h5 class="fs-14 text-truncate">
                  <router-link to="/ecommerce/product-details" class="text-dark"
                    >{{item.detail_item.Nome}}</router-link
                  >
                </h5>
                <ul class="list-inline text-muted">
                  <li class="list-inline-item">
                    Codice Prodotto : <span class="fw-medium">{{item.gcode}}</span>
                  </li>
                  <li class="list-inline-item">
                    Codice EAN : <span class="fw-medium">{{item.detail_item.ean}}</span>
                  </li>
                </ul>

                <div class="input-step">
                  <button type="button" class="minus shadow" @click="editQty(item._id,'–')">–</button>
                  <input
                    type="number"
                    class="product-quantity"
                    v-model="item.detail_item.qta"
                    min="0"
                    max="10000"
                    @blur="addTC(item.gcode, item.CodMarca,item.detail_item.qta)"
                  />
                  <button type="button" class="plus shadow" @click="editQty(item._id,'+')">+</button>
                </div>
              </div>
              <div class="col-sm-auto">
                <div class="text-lg-end">
                  <p class="text-muted mb-1">Prezzo Unitario:</p>
                  <h5 class="fs-14">
                    <template v-if="checkVar(item.detail_item.prezzoListino)=='number'">
                      &euro;<span id="ticket_price" class="product-price">{{formatPrice(item.detail_item.prezzoListino)}}</span>
                    </template>
                  </h5>
                </div>
              </div>
            </div>
          </div>
          
          <div class="card-footer">
            <div class="row align-items-center gy-3">
              <div class="col-sm">
                <div class="d-flex flex-wrap my-n1">
                  <div>
                    <a
                      href="javascript:void(0)"
                      class="d-block text-body p-1 px-2"
                      @click=removeItem(item._id)
                      ><i
                        class="ri-delete-bin-fill text-muted align-bottom me-1"
                      ></i>
                      Rimuovi</a
                    >
                  </div>
                  <div>
                    <a href="#" class="d-block text-body p-1 px-2"
                      ><i class="ri-star-fill text-muted align-bottom me-1"></i>
                      Aggiungi ai preferiti</a
                    >
                  </div>
                </div>
              </div>
              <div class="col-sm-auto">
                <div class="d-flex align-items-center gap-2 text-muted">
                  <div>Totale :</div>
                  <h5 class="fs-14 mb-0">
                    &euro;<span class="product-line-price">{{ formatPrice(item.detail_item.prezzo) }}</span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        
        </div>
               <div>

        </div>
    </div> 
    <div class="text-end mb-4">
       
    </div>
        <div class="text-end mb-4">
            <button type="button" @click="completeOrx( orderList.header.orx,'completed')" class="btn btn-success btn-label right ms-auto">    
              <i class="ri-arrow-right-line label-icon align-bottom fs-16 ms-2"></i>Concludi quotazione
            </button>
        </div>
      </div>
      <!-- end col -->

      <div class="col-xl-4">
        <div class="sticky-side-div">
          <div class="card">
            <div class="card-header border-bottom-dashed">
              <h5 class="card-title mb-0">Sommario </h5>
            </div>
            <div class="card-header bg-soft-light border-bottom-dashed">
              <div class="text-center">
                <h6 class="mb-2">
                  Hai un codice promozionale ?
                </h6>
              </div>
              <div class="hstack gap-3 px-3 mx-n3">
                <input
                  class="form-control me-auto"
                  type="text"
                  placeholder="INSERISCI CODICE PROMO VALIDO"
                  aria-label="Inserisci codice qui..."
                />
                <button type="button" class="btn btn-success w-xs">
                  Applica
                </button>
              </div>
            </div>
            <div class="card-body pt-2">
              <div class="table-responsive">
                <table class="table table-borderless mb-0">
                  <tbody>
                    <tr>
                      <td>SubTotale :</td>
                      <td class="text-end" id="cart-subtotal">&euro; -</td>
                    </tr>
                    <tr>
                      <td>
                        Sconto <span class="text-muted"></span> :
                      </td>
                      <td class="text-end" id="cart-discount">- &euro; -</td>
                    </tr>
<!--                <tr>
                      <td>Shipping Charge :</td>
                      <td class="text-end" id="cart-shipping">&euro; -</td>
                    </tr>
                    <tr>
                      <td>Estimated Tax (12.5%) :</td>
                      <td class="text-end" id="cart-tax">&euro; 44.99</td>
                    </tr>-->
                    <tr class="table-active">
                      <th>Totale (EUR) :</th>
                      <td class="text-end">
                        <span class="fw-semibold" id="cart-total">
                         &euro;<span class="product-line-price">{{ formatPrice(getCart.totalPrice) }}</span>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- end table-responsive -->
            </div>
          </div>

        </div>
        <!-- end stickey -->
      </div>
    </div>
    <!-- end row -->
    <!-- removeItemModal -->
    <div
      id="removeItemModal"
      class="modal fade zoomIn"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="close-modal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mt-2 text-center">
              <lottie
                colors="primary:#ffbe0b,secondary:#f06548"
                :options="defaultOptions"
                :height="100"
                :width="100"
              />
              <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                <h4>{{$t("t-delete-confirm-setting-title")}}</h4>
                <p class="text-muted mx-4 mb-0">
                  {{$t("t-delete-confirm-setting-text")}}
                </p>
              </div>
            </div>
            <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
              <button
                type="button"
                class="btn w-sm btn-light"
                data-bs-dismiss="modal"
              >
                {{$t("t-delete-confirm-cancel-button-text")}}
              </button>
              <button
                type="button"
                class="btn w-sm btn-danger"
                id="remove-product"
              >
                {{ $t("t-delete-confirm-setting-confirmButtonText")}}
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->
  </Layout>
</template>
